import {
  CalendarIcon,
  ChartBarIcon,
  CogIcon,
  HeartIcon,
  InformationCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/outline'

import { ENABLE_ARCHIVED_GAMES } from '../../constants/settings'
import { GAME_TITLE } from '../../constants/strings'

type Props = {
  setIsInfoModalOpen: (value: boolean) => void
  setIsStatsModalOpen: (value: boolean) => void
  setIsDatePickerModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
  setIsCreditsModalOpen: (value: boolean) => void
  setIsSupportModalOpen: (value: boolean) => void
}

export const Navbar = ({
  setIsInfoModalOpen,
  setIsStatsModalOpen,
  setIsDatePickerModalOpen,
  setIsSettingsModalOpen,
  setIsCreditsModalOpen,
  setIsSupportModalOpen,
}: Props) => {
  return (
    <div className="navbar">
      <div className="navbar-content px-5 xxs:px-1 short:h-auto">
        <div className="flex">
          {/* Apply left margin to all except first */}
          <InformationCircleIcon
            className="h-6 w-6 cursor-pointer stroke-gray-500 hover:stroke-black dark:stroke-slate-300 dark:hover:stroke-white xxs:h-5 xxs:w-5"
            onClick={() => setIsInfoModalOpen(true)}
          />
          <UserGroupIcon
            className="ml-2 h-6 w-6 cursor-pointer stroke-gray-500 hover:stroke-black dark:stroke-slate-300 dark:hover:stroke-white xxs:ml-1 xxs:h-5 xxs:w-5"
            onClick={() => setIsCreditsModalOpen(true)}
          />
          <HeartIcon
            className="ml-2 h-6 w-6 cursor-pointer stroke-gray-500 hover:stroke-black dark:stroke-slate-300 dark:hover:stroke-white xxs:ml-1 xxs:h-5 xxs:w-5"
            onClick={() => setIsSupportModalOpen(true)}
          />
        </div>
        <p className="game-title -ml-12 font-serif text-2xl font-semibold dark:text-white xxs:-ml-6 xxs:text-xl">
          {GAME_TITLE}
        </p>
        <div className="right-icons">
          {/* Apply right margin to all except last */}
          <ChartBarIcon
            className="mr-2 h-6 w-6 cursor-pointer stroke-gray-500 hover:stroke-black dark:stroke-slate-300 dark:hover:stroke-white xxs:mr-1 xxs:h-5 xxs:w-5"
            onClick={() => setIsStatsModalOpen(true)}
          />
          {ENABLE_ARCHIVED_GAMES && (
            <CalendarIcon
              className="mr-2 h-6 w-6 cursor-pointer stroke-gray-500 hover:stroke-black dark:stroke-slate-300 dark:hover:stroke-white xxs:mr-1 xxs:h-5 xxs:w-5"
              onClick={() => setIsDatePickerModalOpen(true)}
            />
          )}
          <CogIcon
            className="h-6 w-6 cursor-pointer stroke-gray-500 hover:stroke-black dark:stroke-slate-300 dark:hover:stroke-white xxs:h-5 xxs:w-5"
            onClick={() => setIsSettingsModalOpen(true)}
          />
        </div>
      </div>
      <hr></hr>
    </div>
  )
}
